<template>
  <v-container my-10>
    <v-layout align-center justify-center>
      <v-progress-circular
        :size="55"
        :width="4"
        indeterminate
        class="mt-12"
        v-if="loading"
        color="primary"
      ></v-progress-circular>
      <v-card v-else max-width="650px" width="650px">
        <v-card-title class="ml-4">
          <span class="headline" v-if="editView">Edit Task</span>
          <span class="headline" v-else>New Task</span>

          <v-spacer></v-spacer>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              v-if="editView && checkFinishedTask"
              @click="reopenTask($route.params.id)"
              ><v-icon small class="mx-2">mdi-lock-open</v-icon>Reopen
              task</v-btn
            >
            <v-btn
              color="primary"
              text
              v-if="editView"
              @click="previewTask($route.params.id)"
              ><v-icon small class="mx-2">mdi-calendar-check</v-icon
              >Preview</v-btn
            >
            <v-btn
              text
              color="red"
              class="mr-1"
              v-if="editView"
              @click="activateDeleteDialog()"
            >
              <v-icon small class="mx-2">mdi-delete</v-icon>Delete</v-btn
            >
          </v-card-actions>
        </v-card-title>

        <v-card-text class="pb-0">
          <v-row no-gutters>
            <v-col cols="12">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <v-form
                      ref="formTitle"
                      v-model="validTitle"
                      lazy-validation
                      v-on:keydown.enter.stop=""
                    >
                      <v-text-field
                        label="Title*"
                        persistent-hint
                        v-model="editedItem.name"
                        hint="Required"
                        :rules="titleRule"
                        autocomplete="new-password"
                        :disabled="checkFinishedTask"
                      />
                    </v-form>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <v-select
                      label="Status"
                      v-model="editedItem.status"
                      :items="statusOptions"
                      item-text="name"
                      item-value="status"
                      :disabled="checkFinishedTask"
                    >
                    </v-select>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="6" v-if="scopeOptions.length > 1">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <v-select
                      label="Scope*"
                      v-model="scope"
                      :items="scopeOptions"
                      item-text="name"
                      item-value="uuid"
                      :disabled="checkFinishedTask"
                      persistent-hint
                      :rules="scopeRule"
                      hint="Required"
                      return-object
                      @change="handleScopeChange"
                    >
                    </v-select>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <date-range-picker
                      :initial-data="editedItem.due_date"
                      :hint="suggestedDueDate"
                      @set="handleDueDate"
                      :min-date="$moment().toISOString()"
                      :label="'Due Date'"
                    />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <v-select
                      v-model="editedItem.duration"
                      label="Duration"
                      :items="durations"
                      item-text="duration"
                      item-value="value"
                      :disabled="checkFinishedTask"
                    ></v-select>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <boat-selector
                      :initial-boat="initialBoat"
                      @change="handleBoatSelection"
                      :show-boat-name="true"
                      :filter-by-scope="scope"
                    ></boat-selector>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="grey--text text--darken-1"
                    >Checklists:</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6">
              <v-btn
                color="primary"
                v-if="!checkFinishedTask"
                class="float-right mt-2"
                text
                @click="openChecklistSelector"
                ><v-icon class="mr-1">mdi-plus-circle</v-icon> Add
                checklist</v-btn
              >
              <!-- @click="createChecklist" -->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <template v-for="(checklist, index) in editedItem.checklists">
                <checklist-add-edit
                  :key="checklist.vueId"
                  :professional="editedItem.crm_partner_uuid"
                  :checklist-index="index"
                  :checklist="checklist"
                  @update:checklist="updateChecklist"
                  @delete:checklist="deleteChecklist"
                  :content-saved.sync="contentSaved"
                  :disabled="checkFinishedTask"
                  :boat-disabled="isBoatDisabled"
                  :show-boat="false"
                  :filter-by-scope="scope"
                ></checklist-add-edit>
              </template>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <v-textarea
                      label="Comment"
                      v-model="editedItem.description"
                      rows="3"
                      auto-grow
                      :disabled="checkFinishedTask"
                    >
                    </v-textarea
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="editView && editedItem.attachments.length">
            <v-col cols="12">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    v-if="editedItem.attachments.length"
                    class="mb-1"
                    >Attachments:</v-list-item-title
                  >
                  <v-list-item-action-text>
                    <template
                      v-for="(attachment, index) in editedItem.attachments"
                    >
                      <v-row :key="index">
                        <v-col cols="10">
                          <v-btn @click="openAttachment(attachment)" text
                            ><v-icon color="grey darken-2"
                              >mdi-file-image</v-icon
                            >
                            <v-list-item-subtitle
                              class="text-none subtitle-2 ml-2"
                              >{{ attachment.title }}</v-list-item-subtitle
                            ></v-btn
                          >
                        </v-col>
                        <v-col cols="2" v-if="editView">
                          <v-btn
                            :disabled="checkFinishedTask"
                            text
                            @click="removeTaskAttachment(index)"
                            ><v-icon color="red">mdi-delete</v-icon></v-btn
                          >
                        </v-col>
                      </v-row>
                    </template>
                  </v-list-item-action-text>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <template>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close()">Cancel</v-btn>
            <v-btn color="primary" text class="mr-2" @click="saveTask"
              >Save</v-btn
            >
          </template>
        </v-card-actions>
      </v-card>
    </v-layout>
    <delete-dialog
      :dialog="deleteDialog"
      :item="taskName"
      @agree="remove"
      v-show="false"
    />
    <checklist-template-selector
      :dialog="checklistSelectorDialog"
      v-if="checklistSelectorDialog.active"
      @create="handleCreateChecklist"
      @close="handleCloseChecklist"
      @add="handleAddChecklist"
    >
    </checklist-template-selector>
  </v-container>
</template>

<script>
import api from "@/utils/api.js";
import snackbar from "@/utils/snackbar";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import TaskAssigneeSelector from "@/components/tasks/TaskAssigneeSelector";
import { mapGetters, mapState } from "vuex";
import ChecklistAddEdit from "@/components/tasks/checklists/ChecklistAddEdit.vue";
import ChecklistTemplateSelector from "@/components/tasks/checklists/ChecklistTemplateSelector.vue";
import DateRangePicker from "@/components/tasks/DateRangePicker";
import BoatSelector from "@/components/tasks/BoatSelector.vue";

export default {
  components: {
    DateRangePicker,
    ChecklistAddEdit,
    DeleteDialog,
    BoatSelector,
    ChecklistTemplateSelector,
  },
  data: () => ({
    servicingPartnerRule: [(v) => !!v || "Servicing Partner is required"],
    titleRule: [(v) => !!v || "Title is required"],
    scopeRule: [(v) => !!v || "Scope is required"],
    checklistSelectorDialog: { active: false, response: null },
    scope: {},
    validDealer: true,
    validTitle: true,
    deleteDialog: false,
    boatSearch: "",
    taskName: "",
    statusOptions: [
      { status: "NOT_STARTED", name: "Not started" },
      { status: "IN_PROGRESS", name: "In progress" },
      { status: "COMPLETED", name: "Completed" },
    ],
    durations: [
      { duration: "1h", value: 60 },
      { duration: "2h", value: 120 },
      { duration: "4h", value: 240 },
      { duration: "8h", value: 480 },
      { duration: "12h", value: 720 },
      { duration: "16h", value: 960 },
      { duration: "24h", value: 1440 },
    ],
    editView: false,
    isBoatDisabled: false,
    suggestedDueDate: "",
    loading: true,
    contentSaved: true,
    professionalSelected: false,
    unrecognizedAssigneeEmail: "",
    initialBoat: null,
    addedFromChecklist: false,
    editedItem: {
      assignee: {},
      crm_partner_uuid: null,
      checklists: [],
      reports: [
        { engine_seconds_requested: "", crm_service: { short_task: "" } },
      ],
      subtasks: [],
      status: "",
    },
  }),

  computed: {
    ...mapGetters(["getMe"]),
    getStatus() {
      return this.statusOptions.find(
        (item) => item.status === this.editedItem.status
      ).name;
    },
    checkFinishedTask() {
      return !!this.editedItem.completed;
    },
    getDuration() {
      let duration = this.durations.find(
        (item) => item.value === this.editedItem.duration
      );
      return typeof duration === "undefined"
        ? this.editedItem.duration
        : duration.duration;
    },

    modelPresent() {
      return !!this.editedItem.crm_boat;
    },
    scopeOptions() {
      return this.getMe.dealerships;
    },
  },

  created() {
    this.getView();

    if (this.editView) {
      this.loadTask();
    } else {
      this.scope =
        this.getMe.dealerships.length !== 0 ? this.getMe.dealerships[0] : {};
      this.editedItem.name = this.$route.query.title;
      this.editedItem.description = this.$route.query.comment;
      this.editedItem.status = this.statusOptions[0].status;
      this.editedItem.checklists = [this.setNewBoatTemplate()];
      this.loading = false;
    }
  },

  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === "SET_SERVICES" &&
        typeof state.task.services !== "undefined"
      ) {
        this.handleExternalTask(state.task, true);
      }
    });
  },

  watch: {
    $route(newValue, oldValue) {
      this.getView();
    },
  },

  methods: {
    handleScopeChange(event) {
      this.scope = event;
      this.scopeAvailable++;
    },
    handleDueDate(date) {
      this.editedItem.due_date = this.$moment(date).toISOString();
    },
    handleExternalTask(task, append = false) {
      if (!append) this.editedItem.checklists = [];

      // if (this.addedFromChecklist) {
      //   return;
      // }

      if (task.from === "template") {
        const templates = task.services.flatMap((service) => {
          return service.checklists.map((element) => {
            let subtasks = element.subtasks.map((subtask) => {
              return { description: subtask.description };
            });
            return this.setTemplate(element.name, subtasks);
          });
        });

        const currentChecklists = this.editedItem.checklists;

        [...currentChecklists, ...templates].forEach((item, index) => {
          this.$set(this.editedItem.checklists, index, item);
        });
        // this.addedFromChecklist = true;
      }
    },
    handleCreateChecklist() {
      this.createChecklist();
      this.handleCloseChecklist();
    },
    handleAddChecklist(items) {
      this.handleExternalTask({ services: items, from: "template" }, true);
      this.handleCloseChecklist();
    },
    handleCloseChecklist() {
      this.checklistSelectorDialog = { active: false, response: null };
      // this.checklistSelectorDialog.active = false;
    },
    openChecklistSelector() {
      api.getTemplateList().then((resp) => {
        if (resp.data.results.length !== 0) {
          this.checklistSelectorDialog.response = resp;
          this.checklistSelectorDialog.active = true;
        } else {
          this.createChecklist();
        }
      });
    },
    handleBoatSelection(selection) {
      this.initialBoat = selection;
      this.editedItem.crm_boat_uuid;
    },
    reopenTask() {
      this.loading = true;
      api
        .reopenTask(this.editedItem.uuid, {
          status: "IN_PROGRESS",
          completed: null,
        })
        .then((resp) => {
          this.loading = false;
          this.editedItem.completed = false;
          this.editedItem.status = "IN_PROGRESS";
        })
        .catch((err) => {
          this.handleError(err.response);
          this.loading = false;
        });
    },

    openAttachment(attachment) {
      window.open(attachment.file_link, "_blank");
      return false;
    },
    deleteChecklist(object) {
      const { checklist, index } = object;
      this.editedItem.checklists.splice(index, 1);
    },
    updateChecklist(object) {
      const { checklist, index } = object;
      this.$set(this.editedItem.checklists, index, checklist);
    },
    createChecklist() {
      this.editedItem.checklists.unshift(this.setNewBoatTemplate());
    },
    //TODO REFACTOR
    setNewBoatTemplate(boat = null, subtasks = []) {
      return {
        vueId: Math.random() * 100000,
        crm_boat: boat,
        subtasks: subtasks,
        crm_boat_uuid: null,
        name: `Checklist ${this.editedItem.checklists.length + 1}`,
        crm_partner_uuid: null,
        crm_partner: {},
        assignee_email: null,
        assignee: {},
        unrecognizedAssigneeEmail: "",
      };
    },

    setSubtaskTemplate(service, subtasks = []) {
      return {
        vueId: Math.random() * 100000,
        crm_boat: service.crm_boat,
        subtasks: subtasks,
        crm_boat_uuid: service.crm_boat.uuid,
        name: service.crm_service.short_task,
        crm_partner_uuid: null,
        crm_partner: {},
        assignee_email: null,
        assignee: {},
        unrecognizedAssigneeEmail: "",
      };
    },

    setTemplate(name, subtasks) {
      return {
        vueId: Math.random() * 100000,
        crm_boat: {},
        subtasks: subtasks,
        crm_boat_uuid: null,
        name: name,
        crm_partner_uuid: null,
        crm_partner: {},
        assignee_email: null,
        assignee: {},
        unrecognizedAssigneeEmail: "",
      };
    },
    //END OF TODO

    professionalChanged(event) {
      this.professionalSelected = typeof event !== "undefined";
    },

    getView() {
      this.editView =
        this.$route.name === "TasksEdit" || this.$route.name == "TasksEdit";
    },

    loadTask() {
      return new Promise((resolve, reject) => {
        api.getTask(this.$route.params.id).then((resp) => {
          this.editedItem = resp.data;
          this.scope = resp.data.crm_partner;
          this.editedItem.checklists.forEach((el) => {
            this.initialBoat = el.crm_boat;
          });
          this.editedItem.priority = Number(this.editedItem.priority);
          this.loading = false;
          resolve();
        });
      });
    },

    close() {
      this.$router.push({
        name: "Tasks",
      });
    },

    previewTask(taskUuid) {
      this.$router
        .push({
          name: "TasksDetails",
          params: { id: taskUuid },
        })
        .then((resp) => {
          this.loading = false;
        });
    },

    saveTask() {
      if (typeof this.initialBoat !== "undefined") {
        this.editedItem.checklists.forEach((el) => {
          if (this.initialBoat === null) {
            el.crm_boat_uuid = null;
          } else {
            el.crm_boat_uuid = this.initialBoat.uuid;
          }
        });
      }

      const checkIfChecklistExists = () => {
        return this.editedItem.checklists.some((checklist) => {
          return checklist.subtasks.length !== 0;
        });
      };

      if (this.$refs.formTitle.validate() && checkIfChecklistExists()) {
        if (this.editedItem.status === "COMPLETED") {
          this.editedItem.completed = new Date();
        } else {
          this.editedItem.completed = null;
        }

        // this.editedItem.crm_group_uuid = this.getMe.crm_groups[0].uuid;

        this.editedItem.crm_partner_uuid = this.scope.uuid;

        if (typeof this.editedItem.attachments !== "undefined") {
          this.editedItem.attachment_uuids = this.editedItem.attachments.map(
            (item) => item.uuid
          );
        }

        this.editedItem.checklists = this.editedItem.checklists.filter(
          (checklist) => {
            return checklist.subtasks.length !== 0;
          }
        );

        this.editedItem.checklists.forEach((checklist) => {
          if (checklist.crm_partner_uuid === "other") {
            checklist.crm_partner_uuid = null;
            checklist.assignee_email = checklist.unrecognizedAssigneeEmail;
            delete checklist.unrecognizedAssigneeEmail;
          }
        });

        if (this.editView) {
          this.loading = true;
          //false parameter is here for disabling error handling
          api
            .updateTask(this.editedItem.uuid, this.editedItem, false)
            .then((resp) => {
              snackbar.success("Successfully saved");
              this.previewTask(resp.data.uuid);
            })
            .catch((err) => {
              this.handleError(err.response);
              this.loading = false;
            });
        } else {
          //false parameter is here for disabling error handling
          api
            .createTask(this.editedItem, false)
            .then((resp) => {
              snackbar.success("Successfully saved");
              this.previewTask(resp.data.uuid);
            })
            .catch((err) => {
              this.handleError(err.response);
              this.loading = false;
            });
        }
      } else {
        if (!checkIfChecklistExists()) {
          snackbar.error("Checklist should not be empty");
        } else if (this.$refs.formTitle.validate()) {
          snackbar.error("Please fill required fields");
        } else {
          this.handleError();
        }
      }
    },

    removeSubtask(ind) {
      this.editedItem.subtasks.splice(ind, 1);
    },

    saveSubtask(event) {
      let data = this.editedItem.subtasks;

      data.forEach((element) => {
        if (element.completed === true) {
          element.completed = new Date().toISOString();
        }
        if (element.completed === false) {
          element.completed = null;
        }
        if (element.comment === " ") {
          element.comment = "";
        }
      });

      this.contentSaved = false;
      api
        .saveSubtask(this.$route.params.id, data)
        .then((resp) => {
          this.contentSaved = true;
        })
        .catch((err) => {
          this.contentSaved = false;
        });
    },

    removeTaskAttachment(index) {
      this.editedItem.attachments.splice(index, 1);
    },

    handleError(err) {
      snackbar.error();
    },

    activateDeleteDialog() {
      this.deleteDialog = true;
      this.taskName = "task " + this.editedItem.name;
    },

    remove(agreed) {
      if (agreed) {
        api
          .deleteTask(this.editedItem.uuid)
          .then((resp) => {
            snackbar.success("Successfully deleted");
            this.close();
          })
          .catch((err) => {
            snackbar.error();
          });
      }
      this.deleteDialog = false;
    },
  },
};
</script>
<style scoped></style>
